import React, { useState } from "react";
import { Upload, Button, message, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RefundService } from "../services/RefundService.ts";

const CSVUploader = ({ onSubmit, text }) => {
  const [file, setFile] = useState(null);

  const handleUpload = (info) => {
    setFile(info.file);
  };

  return (
    <div className="d-flex">
      <Upload
        accept=".csv"
        beforeUpload={() => false} // Prevenir la subida automática
        onChange={handleUpload}
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>{text}</Button>
      </Upload>
      <Button type="primary" onClick={() => onSubmit(file)}>
        Submit
      </Button>
    </div>
  );
};

export default CSVUploader;

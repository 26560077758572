import React from "react";

export const ReimbulsmentLink = ({ marketplace }) => {
  return (
    <div>
      <p>
        Csv amazon ha pagado {marketplace.countryLabel}{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href={`https://sellercentral.amazon.es/payments/event/view?accountType=PAYABLE&pastNumberOfDays=90&eventType=Adjustment&subEventType=BwPInventoryReimbursement&subEventType=FBAInventoryReimbursement&subEventType=MCFInventoryReimbursement&subEventType=AWDInventoryReimbursement&subEventType=BwPReversedReimbursement&subEventType=FBAReversedReimbursement&subEventType=MCFReversedReimbursement&subEventType=AWDReversedReimbursement&transactionstatus=RELEASED&category=DEFAULT&resultsPerPage=10&pageNumber=1&mons_sel_mkid=amzn1.mp.o.${marketplace.marketplaceId}&mons_sel_dir_mcid=amzn1.merchant.d.ADM4UBDVANXEPODPEPR3AT5A4UTA`}
        >
          aquí
        </a>
      </p>
    </div>
  );
};
